<template>
  <div class="saled_catalog_form">
    <div v-loading="is_panding" class="saled_catalog_form-wrapper slide-in-top">
        <div class="saled_catalog_form-wrapper-header">
            <h2>הוספת תמונות</h2>
            <div @click="$emit('close')" class="exit-btn">
               <i class="material-icons">close</i>
            </div>
        </div>
        <div class="saled_catalog_form-wrapper-field">
            <h3>שם האוסף</h3>
            <el-input v-model="values.collection_name" placeholder="שם האוסף" />
        </div>
        <div class="saled_catalog_form-wrapper-field">
            <h3>מתאריך</h3>
            <el-input type="date" v-model="values.start_date" />
        </div>
        <div class="saled_catalog_form-wrapper-field">
            <h3>עד תאריך</h3>
            <el-input type="date" v-model="values.end_date" />
        </div>
        <FileUpload 
            ref="images_preview"
            @uploader="handle_upload_images_files"
            uploadLabel="העלאה" chooseLabel="בחירה" cancelLabel="נקה" 
            :customUpload="true" :multiple="true" 
            accept="image/*">
            <template #empty>
                <p style="text-align:center;">גרירת תמונות לכאן</p>
                <i @click="handle_choose_images" class="pi pi-upload upload-icon"></i>
            </template>
        </FileUpload>
        <div class="images-grid">
            <template v-for="image in values.images" :key="image.url">
                <div class="box">
                    <div class="box-image">
                        <img :src="image.image_url" alt="">
                    </div>
                    <div class="box-order">
                        <i @click="handle_delete_img(image.image_url)" class="pi pi-trash delete-icon"></i>
                         <el-switch
                            v-model="image.display"
                            class="ml-2"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        />
                        <input class="order-input" v-model="image.order" type="number">
                    </div>
                </div>
            </template>
        </div>
        <el-button v-if="!edit" @click="handle_submit" style="width:100%;" :disabled="is_panding" type="success">סיום</el-button>
        <el-button v-if="edit" @click="handle_edit" style="width:100%;" :disabled="is_panding" type="warning">עדכון</el-button>
    </div>
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload'
import { ref } from '@vue/reactivity'
import upload_image_storage from '../../../../Methods/use_general_storage'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import {store_collection_in_db,edit_collection_in_db} from './scripts/scripts'


export default {
    props:['edit'],
    components:{
        FileUpload
    },
    emits:['close','submit','submit_edit'],
    setup(props,{emit}){
        const err_msg = ref(false)

        const {url,uploadImage,delete_image_by_url} = upload_image_storage()
        const is_panding = ref(false)
        const images_preview = ref()
        

        const values = ref({
            created_at:new Date(),
            collection_name:'',
            start_date:'',
            end_date:'',
            images:[]
        })
        
        const handle_choose_images = () => {
            images_preview.value.choose()
        }

        const handle_delete_img = async(url) => {
            await delete_image_by_url(url)
            values.value.images = values.value.images.filter(image=>image.image_url!=url)
        }

        const validation = () => {
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'collection_name':
                        if(!value){
                            err_msg.value = 'עליך לרשום שם האוסף!'
                            return false
                        }
                        break;
                    case 'start_date':
                        if(!value){
                            err_msg.value = 'עליך להזין תאריך התחלה!'
                            return false
                        }
                        break;
                    case 'end_date':
                        if(!value){
                            err_msg.value = 'עליך להזין תאריך סיום!'
                            return false
                        }
                        break;
                    case 'images':
                        if(value.length==0){
                            err_msg.value = 'עליך להעלות תמונה אחת לפחות!'
                            return false
                        }
                        else{
                            const set = new Set()
                            for(const image of value){
                                if(set.has(Number(image.order))){
                                    err_msg.value = 'אינך יכול להזין את אותו סדר הצגה יותר מפעם אחת!'
                                    return false
                                }else{
                                    set.add(Number(image.order))
                                }
                            }
                        }
                        break;
                }
            }
            return true
        }

        const handle_upload_images_files = async(images_files) => {
            if(values.value.collection_name){
                is_panding.value = true
                for(const [index, value] of images_files.files.entries()){
                    await uploadImage(value,`SalesCatalog/${values.value.collection_name}/${new Date()}`)
                    values.value.images.push({
                        order:index,
                        image_url:url.value,
                        display:true
                    })
                }
                is_panding.value = false
                images_preview.value.clear()
            }else{
                slide_pop_error('עליך למלא שם האוסף כדי להעלות תמונות!')
            }
        }

        const handle_submit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_panding.value = true
                const uid = await store_collection_in_db(values.value)
                is_panding.value = false
                alert('success','הצלחה',
                `האוסף ${values.value.collection_name} נשמר בהצלחה`)
                .then(()=>{
                    emit('submit',{
                        ...values.value,
                        uid
                    })
                })
            }
        }

        const handle_edit = async() => {
             if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_panding.value = true
                await edit_collection_in_db(values.value)
                is_panding.value = false
                alert('success','הצלחה',
                `האוסף ${values.value.collection_name} עודכן בהצלחה`)
                .then(()=>{
                    emit('submit_edit',values.value)
                })
            }
        }

        const init = () => {
            if(props.edit){
                values.value = props.edit
            }
        }

        init()
        return{
            handle_upload_images_files,
            handle_edit,
            handle_choose_images,
            handle_delete_img,
            handle_submit,
            images_preview,
            values,
            is_panding,
            err_msg,
        }
    }
}
</script>

<style scoped>
    .saled_catalog_form{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .saled_catalog_form-wrapper{
        width: 100%;
        max-width: 500px;
        height: 80%;
        overflow: hidden;
        overflow-y: auto;
        background: #fff;
        border-radius: 10px;
        padding: 5px;
    }
    .saled_catalog_form-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid lightgrey;
        margin-bottom: 5px;
    }
    .saled_catalog_form-wrapper-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }
    .upload-icon{
        text-align: center;
        font-size: 25px;
        color: rgb(43, 143, 236);
        width: 100%;
        font-weight: 600;
        margin-top: 5px;
        cursor: pointer;
    }
    .images-grid{
        margin: 5px 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: minmax(200px,1fr);
        grid-gap: 5px;
        padding: 0 5px;
    }
    .box{
        box-shadow: 0 0 2px rgba(0,0,0,0.2);   
    }
    .box-order{
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
    }
    .box-image{
        width: 100%;
        height: calc(100% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-image img{
        max-width: 80%;
        max-height: 80%;
    }
    .order-input{
        width: 20%;
        height: 100%;
        border: 1px solid lightgray;
        text-align: center;
        
    }
    .delete-icon{
        color: var(--danger);
        font-size: 18px;
        cursor: pointer;
    }
    :global(.p-button.p-component .p-button-label){
        margin: 0 5px;
    }
    :global(.p-button.p-component.p-button-icon-only .p-button-label){
        margin: unset;
    }
    .saled_catalog_form-wrapper::-webkit-scrollbar { width: 0 !important }
    .saled_catalog_form-wrapper { overflow: -moz-scrollbars-none; }
    .saled_catalog_form-wrapper { -ms-overflow-style: none; }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: var(--danger);
        border-radius: 50%;
        cursor: pointer;
    }

    .slide-in-top {
	    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>